import React from "react";
import serviceone from "../../../static/images/serviceone.png";
import actuarialconsulting from "../../../static/images/BusinessConsulting.png";

export default function sectionone() {
  return (
    <div>
      <section
        class="lg:py-16 lg:px-20 bg-white overflow-hidden"
        style={{ fontFamily: "poppins" }}
      >
        {/* <div class="container px-4 mx-auto">
          <div class="flex flex-wrap xl:items-center -m-8">
            <div class="w-full md:w-1/2 p-8">
              <img
                class="pt-12 lg:pt-4 lg:mx-auto transform hover:translate-y-3 transition ease-in-out lg:w-3/4 lg:h-3/4 duration-1000"
                src={actuarialconsulting}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="md:max-w-xl">
                <p class="mb-2 text-sm text-indigo-900 font-semibold uppercase tracking-px">
                  &#x1F44B; Our Services
                </p>
                <h2 class=" mb-16 text-2xl md:text-4xl font-bold text-indigo-800 font-heading tracking-px-n leading-tight">
                  Actuarial Consulting and Valuation
                </h2>
                <div class="flex flex-wrap mb-5 pb-10 -m-8">
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Valuation
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            {" "}
                            liabilities in an insurance company by preparation
                            of regulatory & financial condition reports (FCR)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Preparation
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Preparation of claims reserve estimation and
                            adequacy assessment
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Reinsurance
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Reinsurance appropriateness and sufficiency
                            assessment
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Analysis
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Analysis of claims experience of an insurance
                            company
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
           
              </div>
            </div>
          </div>
        </div> */}

        <section class="overflow-hidden bg-white py-8 lg:py-16">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div class="lg:pr-8">
                <div class="lg:max-w-lg">
                  <h2 class="text-base font-semibold leading-7 text-indigo-600">
                    Our Services
                  </h2>
                  <p class="mt-2 text-3xl font-semibold tracking-tight text-blue-900 sm:text-4xl">
                    Actuarial Consulting and Valuation
                  </p>
                  <p class="mt-6 lg:text-lg leading-8 text-gray-600">
                    Actuarial precision is at the heart of sustainable financial
                    and risk management strategies. At RJ Actuaries and
                    Consultants, we specialize in delivering actuarial solutions
                    that enable businesses to forecast accurately, comply with
                    regulations, and safeguard against uncertainties.
                  </p>
                  
                </div>
              </div>
              <img
                src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw4fHxjb21wdXRlcnxlbnwwfDB8fHwxNjkxODE2NjY3fDA&ixlib=rb-4.0.3&q=80&w=1080"
                alt="Product screenshot"
                class="w-[20rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[38rem] md:-ml-4 lg:-ml-0"
                
              />
            </div>
          </div>
          <div class="max-w-7xl mx-auto">
              

              {/* <div class="grid grid-cols-1 mt-0 md:grid-cols-2 mt:4 lg:grid-cols-2 mt-4 gap-8">
               
                <div class="bg-gray-100 rounded-lg overflow-hidden shadow-lg mt-6">
                  <div class="p-6">
                   
                    <ul class="list-disc ml-6 mt-10">
                    <li>
                      <p>
                        <span className="font-medium">Appointed Actuary: </span>{" "}
                        Ensuring compliance with regulatory requirements and
                        providing strategic insights to insurers and businesses
                        through appointed actuary expertise.
                      </p>
                    </li>
                    <li >
                      <p>
                        <span className="font-medium">
                          Pricing and Reserving Strategies:{" "}
                        </span>{" "}
                        Ensuring compliance with regulatory requirements and
                        providing strategic insights to insurers and businesses
                        through appointed actuary expertise.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="font-medium">Profitability Assessments: </span>{" "}
                        Comprehensive profitability assessments to identify growth opportunities and enhance operational efficiency.
                      </p>
                    </li>
                      
                    </ul>
                  </div>
                </div>

               
                <div class="bg-gray-100 rounded-lg overflow-hidden shadow-lg mt-6">
                  <div class="p-6">
                   
                    <ul class="list-disc ml-6 mt-10">
                    <li>
                      <p>
                        <span className="font-medium">Financial Condition Reports (FCR): </span>{" "}
                        In-depth analyses of financial health to meet regulatory and business planning requirements.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="font-medium">Reinsurance Optimization: </span>{" "}
                        Structuring cost-effective reinsurance programs to mitigate risk and improve capital efficiency.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="font-medium">Embedded Value Calculations: </span>{" "}
                        Providing detailed embedded value analysis for life insurance businesses to ensure transparency and improve decision-making.
                      </p>
                    </li>
                      
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
            <div class="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 md:mt-20">
              <div class="max-w-7xl mx-auto">
                <div class="text-center">
                  <h2 class="text-3xl font-bold text-blue-900 mb-6">
                    Actuary Services
                  </h2>
                 
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                        Appointed Actuary
                      </h3>
                      <p class="text-gray-600">
                      Ensuring compliance with regulatory requirements and providing strategic insights to insurers and businesses through appointed actuary expertise.
                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
              
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Pricing and Reserving Strategies
                      </h3>
                      <p class="text-gray-600">
                      Comprehensive profitability assessments to identify growth opportunities and enhance operational efficiency.                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Profitability Assessments
                      </h3>
                      <p class="text-gray-600">
                        Responsible for determining the pricing structure.
                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Financial Condition Reports (FCR)
                      </h3>
                      <p class="text-gray-600">
                      In-depth analyses of financial health to meet regulatory and business planning requirements.
                      </p>
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Reinsurance Optimization
                      </h3>
                      <p class="text-gray-600">
                      Structuring cost-effective reinsurance programs to mitigate risk and improve capital efficiency.
                      </p>
                    </div>
                    
                  </div>
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    {/* <img
                      class="w-full h-64 object-cover object-center"
                      src={""}
                      // alt="Actuary Image"
                    /> */}
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 mb-2">
                      Embedded Value Calculations
                      </h3>
                      <p class="text-gray-600">
                      Providing detailed embedded value analysis for life insurance businesses to ensure transparency and improve decision-making.
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
        </section>
      </section>
    </div>
  );
}
