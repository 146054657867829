import React from "react"
// import serviceone from '../../../static/images/serviceone.png'

export default function sectiontwo() {
  return (
    <div>
      <section
        class="relative pb-16 lg:pb-24 lg:px-20 bg-white overflow-hidden justify-center"
        style={{ fontFamily: "poppins" }}
      >
        {/* <img class="absolute left-1/2 top-0 transform -translate-x-1/2" src={serviceone} alt=""/> */}
        {/* <div class="relative  container px-4 mx-auto">
          <p class="mb-6 text-sm text-indigo-600 text-center font-semibold uppercase tracking-px">
            Why RJAC ?
          </p>
          <h2 class=" text-2xl md:text-4xl text-center font-bold font-heading tracking-px-n leading-tight text-indigo-800">
            General and Health Insurance Consulting –
          </h2>

          <section class="bg-white pb-6 ">
            <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
              <section class="bg-white pb-6">
                <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 flex justify-between">
                  <div class="">
                    <h1 class="md:text-2xl md:mb-5">
                      General and Health Insurance Consulting
                    </h1>
                    <p class="mb-3 font-semibold">
                      We provide a range of services to Life Insurance
                      companies, these include
                    </p>
                    <ul>
                      <li>- Capital and Risk Modelling</li>
                      <li>- Product design</li>
                      <li>- Pricing</li>
                      <li>- Reserving</li>
                      <li>- Regulatory & compliance reporting</li>
                      <li>- Liability Adequacy Testing</li>
                      <li>- Financial Condition Reports</li>
                      <li>
                        - Reinsurance optimization, appropriateness and
                        sufficiency assessment
                      </li>
                      <li>- IFRS consulting</li>
                      <li>- Financial Due-Diligence services</li>
                    </ul>
                  </div>
                  <div class=" md:ml-32">
                    <h1 class="md:text-2xl md:mb-5">
                      Life Insurance Consulting
                    </h1>
                    <p class="mb-3 font-semibold">
                      We provide a range of services to Life Insurance
                      companies, these include
                    </p>
                    <ul>
                      <li>- Risk Assessment</li>
                      <li>- Product design & filing</li>
                      <li>- Pricing</li>
                      <li>- Financial Reporting</li>
                      <li>- Regulatory & compliance reporting</li>
                      <li>- Reinsurance optimization</li>
                      <li>- Analysis of Surplus calculations</li>
                      <li>- Embedded value calculations</li>
                      <li>- IFRS consulting</li>
                      <li>- Financial Due-Diligence services</li>
                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div> */}

        <div class="container px-4 mx-auto">
          <p class="mb-6 text-xl text-indigo-600 text-center font-semibold uppercase tracking-px">
            Why Chose RJAC ?
          </p>
          

          <section class="bg-white pb-6">
            {/* <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h1 class="md:text-2xl md:mb-5">
                    General and Health Insurance Consulting
                  </h1>
                  <p class="text-sm mb-3 font-semibold">
                    We provide a range of services to Life Insurance companies,
                    these include
                  </p>
                  <ul>
                    <li>- Capital and Risk Modelling</li>
                    <li>- Product design</li>
                    <li>- Pricing</li>
                    <li>- Reserving</li>
                    <li>- Regulatory & compliance reporting</li>
                    <li>- Liability Adequacy Testing</li>
                    <li>- Financial Condition Reports</li>
                    <li>
                      - Reinsurance optimization, appropriateness and
                      sufficiency assessment
                    </li>
                    <li>- IFRS consulting</li>
                    <li>- Financial Due-Diligence services</li>
                  </ul>
                </div>
                <div>
                  <h1 class=" md:text-2xl md:mb-5">
                    Life Insurance Consulting
                  </h1>
                  <p class="text-sm mb-3 font-semibold">
                    We provide a range of services to Life Insurance companies,
                    these include
                  </p>
                  <ul>
                    <li>- Risk Assessment</li>
                    <li>- Product design & filing</li>
                    <li>- Pricing</li>
                    <li>- Financial Reporting</li>
                    <li>- Regulatory & compliance reporting</li>
                    <li>- Reinsurance optimization</li>
                    <li>- Analysis of Surplus calculations</li>
                    <li>- Embedded value calculations</li>
                    <li>- IFRS consulting</li>
                    <li>- Financial Due-Diligence services</li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div class="max-w-7xl mx-auto">
              

              <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
                {/* <!-- General and Health Insurance Consulting --> */}
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Regulatory Expertise
                    </h3>
                    <ul class="list-disc ml-6">
                      <li> We ensure compliance with regional and international standards, reducing regulatory risk.</li>
                      
                    </ul>
                  </div>
                </div>
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Tailored Solutions
                    </h3>
                    <ul class="list-disc ml-6">
                      <li> Our strategies are customized to fit the unique needs of insurers, corporates, and financial institutions.</li>
                      
                    </ul>
                  </div>
                </div>
                <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                  <div class="p-6">
                    <h3 class="text-xl font-semibold text-blue-900 mb-4">
                    Proven Track Record
                    </h3>
                    <ul class="list-disc ml-6">
                      <li> With decades of experience, RJAC is a trusted partner for actuarial excellence.</li>
                      
                    </ul>
                  </div>
                </div>

                
              </div>
            </div>

            {/* ......................................................... */}

            <div class="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 md:mt-20">
              <div class="max-w-7xl mx-auto align-middle">
                <div class="text-left">
                  <h2 class="text-3xl font-bold text-blue-900 mb-6">
                  Key Outcomes for Clients
                  </h2>
                  
                </div>
                {/* <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                   
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 ">
                      Improved financial stability and accuracy in projections.
                      </h3>
                     
                    </div>
                  </div>
                  

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900  text-center ">
                        Valuation Actuary
                      </h3>
                      
                    </div>
                  </div>

                  <div class="bg-white rounded-lg overflow-hidden shadow-lg">
                    
                    <div class="p-6">
                      <h3 class="text-xl font-semibold text-blue-900 ">
                        Pricing Actuary
                      </h3>
                      
                    </div>
                  </div>

                 

                
                </div> */}
                <div >
                <ul class="list-disc ml-6">
                      <li className="text-xl">Improved financial stability and accuracy in projections.</li>
                      <li className="text-xl">Enhanced compliance with IFRS 17, local regulations, and accounting standards.</li>
                      <li className="text-xl">Optimized reinsurance and risk strategies.</li>
                      
                    </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}
